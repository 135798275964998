import {
  CatalogCategory,
  CatalogCompany,
  CatalogCompanyType,
  CatalogItem,
  CatalogItemMoq,
  CatalogItemType,
} from '../types/catalog';
import {
  ICustomization,
  MoqFilterOption,
  SamplePriceFilterOption,
  SourcingHomeTab,
  SubcategoryMap,
} from '../types/sourcing-marketplace';
import { CategoryConfig, VerticalName } from '@/shared/types/category';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import { CreatorInvoiceItemType } from '../types/creator-order';
import { STATIC_FILE_URL } from '../constants';
import { capitalize, first, forEach, get, isEmpty, map, round, sortBy, uniq, values } from 'lodash';

export const SUBGROUP_SEPARATOR = ' - ';

export const getSortedMoqInfo = (item: CatalogItem): CatalogItemMoq[] => {
  const moqInfoPreSort = (item?.meta?.moqInfo || []).filter((moq: any) => !!moq?.qty && !!moq?.price);
  const moqInfo = sortBy(moqInfoPreSort, 'qty');
  return moqInfo;
};

export const getVerticalName = (vertical: VerticalName, isSingular?: boolean) => {
  if (vertical === VerticalName.SERVICES) {
    return isSingular ? 'freelancer' : 'freelancers';
  }
  return isSingular ? 'factory' : 'factories';
};

export const getSupplierName = (supplier: CatalogCompany) => {
  const supplierType = getVerticalName(
    supplier?.type === CatalogCompanyType.SPECIALIST ? VerticalName.SERVICES : VerticalName.PRODUCTS,
    /* isSingular */ true,
  );
  const firstWordOfName = first(supplier?.name?.split(' '));
  return `${firstWordOfName} ${capitalize(supplierType)}`;
};

export const getFormattedAvgResponseTime = (supplier: CatalogCompany) => {
  const avgRespMinutes = supplier?.avgRespMinutes || 0;
  if (avgRespMinutes <= 0) {
    return '1 day';
  }
  dayjs.extend(duration);
  dayjs.extend(relativeTime);
  const formattedStr = dayjs.duration(avgRespMinutes, 'minutes').humanize();
  if (formattedStr.substring(0, 2) === 'a ') {
    return `1 ${formattedStr.substring(2)}`;
  }
  return formattedStr;
};

export const getYearlySales = (supplier: CatalogCompany) => {
  return supplier?.yearlySales || '< $500,000';
};

export const getSupplierCapabilities = (supplier: CatalogCompany, isServices: boolean, maxLen?: number) => {
  const customizations = supplier?.productionCustomizations || [];
  const list = customizations.map((x) => x.text);
  if (list.length < 2) {
    if (isServices) {
      list.push('Vetted Pietra Freelancer');
      list.push(`Additional Services`);
    } else {
      list.push('Contract Manufacturer');
      list.push('Vetted Pietra Supplier');
    }
  }
  return list.length > maxLen ? list.splice(0, maxLen) : list;
};

export const getCatalogItemCustomizationArray = (item: CatalogItem): ICustomization[] => {
  return (item?.customizations || [])
    .map((x, i) => {
      try {
        const type = get(x, 'type');
        const updated = { ...x, identifier: 'customization-' + i };
        if (type === 'colors') {
          updated.value = parseColorsString(x.value).map((c) => ({ ...c, identifier: 'customization-' + i }));
        } else if (type === 'tags') {
          updated.value = JSON.parse(x.value);
        } else if (type === 'image_opts') {
          updated.value = x.value
            .split(';')
            .map((x) => x.split('|'))
            .map(([name, image]) => ({ name, image, identifier: 'customization-' + i }))
            .filter((x) => x.image);
        }
        return updated;
      } catch (e) {
        console.error(e);
        return undefined;
      }
    })
    .filter((x) => !!x?.type);
};

export function parseColorsString(colorsString: string) {
  const colors = colorsString.split(';');
  const colorInfo = [];
  colors.forEach((color) => {
    const parts = color.split('|');
    if (!parts[0] || parts[0].length === 0) {
      return;
    }
    const dict: any = {
      name: parts[0],
    };
    if (parts.length > 1) {
      let hexCode = parts[1];
      if (!hexCode.startsWith('#')) {
        hexCode = `#${hexCode}`;
      }
      dict.hexCode = hexCode;
    }
    if (parts.length > 2) {
      dict.colorVariant = parts[2];
    }
    colorInfo.push(dict);
  });
  return colorInfo;
}

export const convertToInvoiceItemType = (catalogItem: CatalogItem) => {
  const vertical: CatalogItemType = catalogItem.meta?.type;
  switch (vertical) {
    case 'packaging':
      return CreatorInvoiceItemType.PACKAGING;
    case 'services':
      return CreatorInvoiceItemType.SERVICES;
    case 'product':
    default:
      return CreatorInvoiceItemType.PRODUCT;
  }
};

export const getProductCategories = (categoryConfig: { [key: string]: CategoryConfig[] }) => {
  return categoryConfig?.[SourcingHomeTab.PRODUCTS];
};

export const getPackagingCategories = (categoryConfig: { [key: string]: CategoryConfig[] }) => {
  return categoryConfig?.[SourcingHomeTab.PACKAGING];
};

export const getServicesCategories = (categoryConfig: { [key: string]: CategoryConfig[] }) => {
  return [
    ...(categoryConfig || {})[SourcingHomeTab.BUSINESS_AND_CREATIVE_SERVICES],
    ...(categoryConfig || {})[SourcingHomeTab.WEBSITE_DEVELOPMENT],
  ];
};

export const getMatchingCategoryAndVertical = (
  categoryHandle: string,
  categoryConfig: { [key: string]: CategoryConfig[] },
) => {
  if (categoryHandle === 'all') {
    return {
      category: {
        key: 'All',
        title: 'Start Sourcing',
        uniqueName: 'all',
        vertical: null,
        image: `${STATIC_FILE_URL}/315508b15a0fa1fcf9e2145f113c5c92.png`,
      },
      vertical: null,
    };
  }

  let category: CategoryConfig;
  let vertical: VerticalName;

  Object.entries(categoryConfig).forEach(([key, value]) => {
    value.forEach((cat: CategoryConfig) => {
      if (cat.key === categoryHandle) {
        category = cat;
        if (Object.values(VerticalName).includes(cat.vertical as VerticalName)) {
          vertical = cat.vertical as VerticalName;
        } else {
          vertical = key as VerticalName;
        }
      }
    });
  });

  return { category, vertical };
};

export const buildSubCategoriesMap = (subcategories: CatalogCategory[]) => {
  const map = {};

  const insertSubcategory = (subcategory: CatalogCategory) => {
    const { vertical, group, subgroup } = subcategory;
    if (!map[vertical]) {
      map[vertical] = {};
    }
    if (!map[vertical][group]) {
      map[vertical][group] = {};
    }
    if (!map[vertical][group][subgroup]) {
      map[vertical][group][subgroup] = subcategory;
    }
  };

  forEach(subcategories, insertSubcategory);
  return map;
};

const getSubgroupSubcategories = (subgroup: CatalogCategory): CatalogCategory[] => [subgroup];

const getGroupSubcategories = (group: { [key: string]: CatalogCategory }): CatalogCategory[] => values(group);

const getVerticalSubcategories = (vertical: { [key: string]: { [key: string]: CatalogCategory } }): CatalogCategory[] =>
  [].concat(...map(values(vertical), getGroupSubcategories));

const getAllSubcategories = (subcategoriesMap: SubcategoryMap): CatalogCategory[] =>
  [].concat(...map(values(subcategoriesMap), getVerticalSubcategories));

export const getSubcategories = (
  subcategoriesMap: SubcategoryMap,
  vertical?: string,
  group?: string,
  subgroup?: string,
): CatalogCategory[] => {
  if (isEmpty(subcategoriesMap)) {
    return [];
  }
  const formatSubcategories = (subCats) => {
    return map(subCats, (sc) => ({
      ...sc,
      _label: !group ? uniq([sc?.group, sc?.subgroup]).join(SUBGROUP_SEPARATOR) : sc?.subgroup,
    }));
  };

  let subCategories: CatalogCategory[] = [];
  if (!vertical) {
    subCategories = getAllSubcategories(subcategoriesMap);
  } else if (!group) {
    subCategories = getVerticalSubcategories(subcategoriesMap[vertical]);
  } else if (!subgroup) {
    subCategories = getGroupSubcategories(subcategoriesMap[vertical][group]);
  } else {
    subCategories = getSubgroupSubcategories(subcategoriesMap[vertical][group][subgroup]);
  }
  return formatSubcategories(subCategories);
};

export const samplePriceFilterToString = (samplePrice: SamplePriceFilterOption) => {
  switch (samplePrice) {
    case SamplePriceFilterOption.FIVE_TO_FIFTY:
      return '5,50';
    case SamplePriceFilterOption.FIFTY_TO_HUNDRED:
      return '50,100';
    case SamplePriceFilterOption.HUNDRED_PLUS:
      return '100';
    case SamplePriceFilterOption.NO_SAMPLE_PRICE:
      return 'custom';
    case SamplePriceFilterOption.ALL:
    default:
      return null;
  }
};

export const samplePriceStringToApiParam = (samplePrice: string) => {
  switch (samplePrice) {
    case '5,50':
      return { minSamplePrice: 5, maxSamplePrice: 50 };
    case '50,100':
      return { minSamplePrice: 50, maxSamplePrice: 100 };
    case '100':
      return { minSamplePrice: 100 };
    case 'custom':
      return { nullSamplePrice: true };
    default:
      return null;
  }
};

export const moqFilterToString = (moqFilter: MoqFilterOption) => {
  switch (moqFilter) {
    case MoqFilterOption.ZERO_TO_HUNDRED:
      return '0,100';
    case MoqFilterOption.HUNDRED_TO_THOUSAND:
      return '100,1000';
    case MoqFilterOption.THOUSAND_PLUS:
      return '1000';
    case MoqFilterOption.NO_MOQ:
      return 'custom';
    case MoqFilterOption.ALL:
    default:
      return null;
  }
};

export const moqStringToApiParam = (moq: string) => {
  switch (moq) {
    case '0,100':
      return { minMOQ: 0, maxMOQ: 100 };
    case '100,1000':
      return { minMOQ: 100, maxMOQ: 1000 };
    case '1000':
      return { minSamplePrice: 1000 };
    case 'custom':
      return { nullMOQ: true };
    default:
      return null;
  }
};

export const isMatchingCategory = (categoryA: CategoryConfig, categoryB: CatalogCategory) => {
  const isSameVertical = categoryA.vertical === categoryB.vertical;
  const isSameGroup = categoryA.group === categoryB.group;
  const isSameSubgroup = categoryA.subgroup === categoryB.subgroup;
  const isSameGroupOrNull = !categoryA.group || isSameGroup;
  const isSameSubgroupOrNull = !categoryA.subgroup || isSameSubgroup;
  const categoryHasNotBeenDeleted = !categoryB.deletedAt;
  return isSameVertical && isSameGroupOrNull && isSameSubgroupOrNull && categoryHasNotBeenDeleted;
};

export const getMatchedFieldName = (matchedField: string) => {
  return {
    bio: 'description',
    category_keywords: 'category',
    combined_details: 'capabilities',
    country: 'country',
    customizations: 'customization options',
    description: 'description',
    group: 'category',
    name: 'name',
    notable_works: 'notable clients',
    note_attributes: 'capabilities',
    product_description: 'description',
    product_name: 'name',
    product_title: 'catalog',
    products: 'catalog',
    production_customizations: 'customization options',
    sample_description: 'description',
    search_keywords: 'capabilities',
    subgroup: 'category',
    supplier_tags: 'capabilities',
    vertical: 'category',
  }[matchedField];
};

export const convertReviewScoreToScale = (score: number) => {
  const normalizedScore = +(score || 0);
  return round(normalizedScore) / 2;
};

export const getCreatorHubSupplierProfilePagePath = (supplierId: number) => {
  return `/supplier-profile/${supplierId}`;
};

export const getCreatorHubProductDetailsPagePath = (productId: number, suffix?: string) => {
  if (suffix) {
    return `/browse-suppliers/catalog-item/${productId}${suffix}`;
  }
  return `/browse-suppliers/catalog-item/${productId}`;
};

export const getCreatorHubSourcingHomePath = () => {
  return '/home';
};
